import React from 'react'
import styles from "../../App.module.scss"

const Footer = () => {
  return (
    <>
    <div className={styles.footer_sec} id='contact'>
    <div className={styles.footer_subsec}>
      <div className={styles.address_sec}>
        <div className={styles.ad_header}>Address Details</div>
        <div className={styles.address}>
          <strong>AARABHYA&nbsp;SYSTEMS&nbsp;PVT&nbsp;LTD</strong>
          2nd Floor, No 13, Mahalakshmi, 21st Main Rd, <br />
          opp. Banashankari Shopping Complex,<br />
          Banashankari Stage II,<br />
          Bengaluru, Karnataka 560070 <br />
          Phone: (+91) 99001 52313 
             </div>

        <div className={styles.prop}>
           pawan@aarabhya.com
        </div>
      </div>
    </div>
  </div>
  <div className={styles.copyrights}>
    © Copyright Aarabhya 2022
  </div>
  </>
  )
}

export default Footer