import React from 'react'
import styles from "./profile.module.scss"
import { FaPhoneAlt } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { IoMdBriefcase } from "react-icons/io";
import { BsGlobe2 } from "react-icons/bs";
// import { FaFacebookF } from "react-icons/fa";
// import { BsInstagram } from "react-icons/bs";
// import { FaLinkedinIn } from "react-icons/fa";
import { IoPersonAddSharp } from "react-icons/io5";
import { BsFillShareFill } from "react-icons/bs";
import { useParams } from 'react-router-dom';
import profiledata from "../../config/profiledetails.json"

const Profile = () => {

  const { name } = useParams()

  return (
    <div className={styles.main_cont}>
      {
        // eslint-disable-next-line array-callback-return
        profiledata.map((data, i) => {
          if (data.nickname===name) {
            return (
              <>
                <div className={styles.above_cont} key={i}>
                  <div className={styles.profile_abovecont}>
                    <div className={styles.v_card}>
                      <div className={styles.profile_pic}></div>
                      <div className={styles.profile_name}>{data.name}</div>
                      {/* <div className={styles.designation}>{data.designation}</div> */}
                    </div>
                    <div className={styles.contact_cont}>
                      <a href={name==="riaz"?"tel:+91 8464835249":"tel:+62 8226741334"} className={`${styles.cont_fields}`}>
                        <FaPhoneAlt className={styles.icon} />
                        <div className={styles.f_name}>Call</div>
                      </a>
                      <a href={name==="riaz"?"mailto:riaz@arvattech.com":"mailto:faazal@arvattech.com"} className={`${styles.cont_fields} ${styles.cont_fields1}`}>
                        <IoSend className={styles.icon} />
                        {/* <input className={styles.email_inp} type="email" name="" id="email"/> */}
                        <p className={styles.f_name} >Email</p>
                      </a>
                      {/* <div className={`${styles.cont_fields} ${styles.cont_fields1}`}>
                        <FaLocationDot className={styles.icon} />
                        <div className={styles.f_name}>Directions</div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className={styles.below_cont}>
                  <div className={styles.below_subcont}>
                    {/* <div className={styles.about_cont}>
                      <p className={styles.about}>
                        
                      </p>
                    </div> */}
                    <div className={styles.perdet_cont}>
                      <div className={styles.perdet_feild}>
                        <div className={styles.perdet_icon_cont}>
                          <FaPhoneAlt className={styles.perdet_icon} />
                        </div>
                        <div className={styles.perdet_content}><p className={styles.text1}>{data.mobile}</p><p className={styles.text2}>Mobile</p></div>
                      </div>
                      <div className={styles.perdet_feild}>
                        <div className={styles.perdet_icon_cont}>
                          <MdEmail className={styles.perdet_icon} />
                        </div>
                        <div className={styles.perdet_content}><p className={styles.text1}>{data.email}</p><p className={styles.text2}>Email</p></div>
                      </div>
                      <div className={styles.perdet_feild}>
                        <div className={styles.perdet_icon_cont}>
                          <IoMdBriefcase className={styles.perdet_icon} />
                        </div>
                        <div className={styles.perdet_content}><p className={styles.text1}>{data.company}</p><p className={styles.text2}>Founder</p></div>
                      </div>
                      <div className={styles.perdet_feild}>
                        <div className={styles.perdet_icon_cont}>
                          <FaLocationDot className={styles.perdet_icon} />
                        </div>
                        <div className={styles.perdet_content}><p className={styles.text2}> 71-75, Shelton Street,<br />
                          Covent Garden, London,<br />
                          WC2H 9JQ, UNITED KINGDOM</p></div>
                      </div>
                      <div className={styles.perdet_feild}>
                        <div className={styles.perdet_icon_cont}>
                          <BsGlobe2 className={styles.perdet_icon} />
                        </div>
                        <div className={styles.perdet_content}><p className={styles.text1}>{data.website}</p><p className={styles.text2}>Website</p></div>
                      </div>
                    </div>
                    {/* <div className={styles.smedia_cont}>
                      <div className={styles.s_title}>Social Media</div>
                      <div className={styles.smedia_icons_cont}>
                        <div className={styles.smedia_icon}>
                          <FaFacebookF className={styles.f_icon} />
                        </div>
                        <div className={`${styles.smedia_icon} ${styles.insta_icon}`}>
                          <BsInstagram className={styles.f_icon} />
                        </div>
                        <div className={`${styles.smedia_icon} ${styles.linkedin_icon}`} >
                          <FaLinkedinIn className={styles.f_icon} />
                        </div>
                      </div>
                    </div> */}
                    <div className={styles.vcard_cont}>
                      <div className={styles.vcard_btn}>
                        <IoPersonAddSharp className={styles.dv_icon} />
                        <p className={styles.dv_text}>Download vCard</p>
                      </div>
                    </div>
                    <div className={styles.share_cont}>
                      <div className={styles.share_btn}>
                        <BsFillShareFill className={styles.share_icon} />
                        <p>Share this page</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
          
        })
      }

    </div>
  )
}

export default Profile