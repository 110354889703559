import React, { useEffect, useRef, useState } from 'react'
import styles from '../../App.module.scss';

import opus from "../../assets/images/opusAttachment.png"
import mobile1 from "../../assets/images/mobile1.png"
import aritficial from "../../assets/images/artifical.png"
import analytics from "../../assets/images/analytics.png"
import software2 from "../../assets/images/software2.png"
import iot2 from "../../assets/images/iot2.png"
import infras from "../../assets/images/infras.png"
import security from "../../assets/images/security.png"
import cloud from "../../assets/images/cloud.png"
import qa from "../../assets/images/qa.png"
import upbutton from "../../assets/images/up.png"

import AOS from 'aos';
import Navbar from '../navbar/Navbar';

const Home = () => {

    const [scrollHeight, setScrollHeight] = useState(0)
  
    const refToTop = useRef<HTMLInputElement>(null);
  
    const onScroll = (event: any) => {
      setScrollHeight(window.scrollY)
    }
    const scrollToTop = () => {
      refToTop.current && refToTop.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  
    useEffect(() => {
      window.addEventListener("scroll", onScroll, { capture: true });
      return () => window.removeEventListener("scroll", onScroll, { capture: true });
    }, []);
  
   
  
    useEffect(() => {
      AOS.init();
    }, [])

  return (
    <>
    <Navbar/>
    <div className={styles.home} ref={refToTop}>
    <div className={styles.sub_section1}>
      
      <div className={styles.first_section}>
        <div className={styles.c_name}><span>AARABHYA</span><span>SYSTEMS</span></div>
        <div className={styles.tagline}>Your Potential, our Passion</div>
      </div>
    </div>
  </div>
  <div className={styles.about} id='about'>
    <div className={styles.sub_section2}>
      <div className={styles.about}><span data-aos="fade-up"
        data-aos-duration="1000">ABOUT</span><span data-aos="fade-up"
          data-aos-duration="1200">US</span>    <img data-aos="fade-up"
            data-aos-duration="1300" src={opus} alt='opus' className={styles.opus} /></div>
      <p className={styles.text_cont}>
        We have a strong background in building applications to the web as well as mobile. Our customized solutions combine the best of emerging cloud technologies and creativity to deliver world class results.
      </p>
      <div className={styles.link}>
        <a href='#services'>SERVICE</a>
        <span></span>
      </div>
    </div>
  </div>

  <div className={styles.services} id='services'>
    <div className={styles.s_heading}>Our Services</div>

    <div className={styles.sect1}>
      <div className={styles.lt1_cont} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>Mobile technology</div>
        <p className={styles.content1}>
          Native app development is the creation of software programs that run on specific devices and platforms. We can build native apps for desktops, smart TVs, and all kinds of gadgets the most popular target devices are smartphones. Unlike websites and web applications, native mobile apps don&apos;t run in the browser. You need to download them from platform-specific app stores such as Apple&apos;s App Store and Google Play.
        </p>
      </div>
      <div data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear" className={styles.sect1_img}>
        <img src={mobile1} alt="mobile1" className={styles.mobile1} />
      </div>
    </div>

    <div className={styles.sect2}>
      <div className={styles.sect1_img} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <img src={aritficial} alt="aritficial" className={styles.mobile1} />
      </div>
      <div className={styles.lt1_cont} data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>Artificial Intelligence</div>
        <p className={styles.content1}>
          Algorithms are one of the most integral parts of artificial intelligence and machine learning.Machine learning is the scientific study of algorithms and statistical models that computer systems use to perform a specific task without using explicit instructions, relying on patterns and inference instead. It is seen as a subset of artificial intelligence
        </p>
      </div>
    </div>

    <div className={styles.sect1}>
      <div className={styles.lt1_cont} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>Analytics</div>
        <p className={styles.content1}>
          Data analytics initiatives can help businesses increase revenues, improve operational efficiency, optimize marketing campaigns and customer service efforts, respond more quickly to emerging market trends and gain a competitive edge over rivals -- all with the ultimate goal of boosting business performance.As data integration combines data from different inputs, it enables the user to drive more value from their data.
        </p>
      </div>
      <div className={styles.sect1_img} data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear">
        <img src={analytics} alt="analytics" className={styles.mobile1} />
      </div>
    </div>

    <div className={styles.sect2}>
      <div className={styles.sect1_img} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <img src={software2} alt="software2" className={styles.mobile1} />
      </div>
      <div className={styles.lt1_cont} data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>Software Development</div>
        <p className={styles.content1}>
          High efficiency helps accelerate development and makes it less prone to errors. There are ways to automate DevOps tasks. Continuous integration servers automate the code testing process, reducing the amount of manual work required. This means that software engineers can focus on completing tasks that can not be automated.DevOps is no more than a set of processes that coordinate to unify development teams and processes to complement software development.
        </p>
      </div>
    </div>

    <div className={styles.sect1}>
      <div className={styles.lt1_cont} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>Embedded & IOT</div>
        <p className={styles.content1}>
          On a larger scale, the Industrial IoT is a key element of Industry 4.0, or smart factory, trend of automation, which brings together modern cloud computing, IIoT, and AI to create intelligent, self-optimizing industrial equipment and facilities.It widely utilizes modern sensor technology to enhance different types of equipment with remote monitoring and maintenance capabilities.
        </p>
      </div>
      <div className={`${styles.sect1_img} ${styles.sect1_img1}`} data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear">
        <img src={iot2} alt="iot2" className={styles.iot2} />
      </div>
    </div>

    <div className={styles.sect2}>
      <div className={styles.sect1_img} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <img src={infras} alt="infras" className={styles.mobile1} />
      </div>
      <div className={styles.lt1_cont} data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>IT Infrastructure</div>
        <p className={styles.content1}>
          In information technology, a backup, or data backup is a copy of computer data taken and stored elsewhere so that it may be used to restore the original after a data loss event. The verb form, referring to the process of doing so, is "back up", whereas the noun and adjective form is "backup". Backups can be used to recover data after its loss from data deletion or corruption, or to recover data from an earlier time.
        </p>
      </div>
    </div>

    <div className={styles.sect1}>
      <div className={styles.lt1_cont} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>Cyber Security</div>
        <p className={styles.content1}>
          The faster and sooner in the software development process you can find and fix security issues, the safer your enterprise will be. Data security refers to protective digital privacy measures that are applied to prevent unauthorized access to computers, databases and websites. Data security also protects data from corruption. Data security is an essential aspect of IT for organizations of every size and type.
        </p>
      </div>
      <div className={styles.sect1_img} data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear">
        <img src={security} alt="security" className={styles.mobile1} />
      </div>
    </div>

    <div className={styles.sect2}>
      <div className={styles.sect1_img} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <img src={cloud} alt="cloud" className={styles.mobile1} />
      </div>
      <div className={styles.lt1_cont} data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>Cloud Consulting</div>
        <p className={styles.content1}>
          In addition to having the greatest breadth of services, AWS also has the deepest functionality within those services. For example, Amazon EC2 offers more types and sizes of compute instances than any other provider, including the most powerful GPU instances for machine learning workloads. AWS also has more than double the number of database services than anyone else, with eleven relational and non-relational database offerings.
        </p>
      </div>
    </div>

    <div className={styles.sect1}>
      <div className={styles.lt1_cont} data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="linear">
        <div className={styles.sect1_header}>QA & Testing</div>
        <p className={styles.content1}>
          TestingXperts help you predict application behavior and performance in a simulated, real-life environment, thus saving you from unpleasant events later, which frustrate customers and impact profitability. We ensure the application is responsive and reliable enough to respond to peak load days like a black Friday sale and can also scale to other future events like product release, acquisitions, new clients, regulatory changes and ongoing business growth.
        </p>
      </div>
      <div className={`${styles.sect1_img} ${styles.sect1_img1}`} data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="linear">
        <img src={qa} alt="qa" className={styles.iot2} />
      </div>
    </div>
  </div>

  <div className={scrollHeight > 55 ? styles.up_btncont : styles.up_btncontnone} onClick={scrollToTop}>
     <img className={styles.up_btn} src={upbutton} alt='upbutton' />
   </div>
 
  
  </>
  )
}

export default Home