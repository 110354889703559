import React from 'react';
import 'aos/dist/aos.css';
import { BrowserRouter as Router ,Routes,Route } from 'react-router-dom';
import Profile from './components/profile/Profile';
import Home from './components/home/Home';
import HomeLayout from './pages/HomeLayout';


function App() {



  return (
    <>
      <Router>
        <Routes>
          <Route  path='/' element={<HomeLayout/>}>
          <Route index  path='/' element={<Home/>}/>
          <Route path="/profile/:name" element={<Profile/>}/>
          </Route>
        </Routes>
      </Router>

    </>
  );
}

export default App;
