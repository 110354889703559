import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import styles from "./HomeLayout.module.scss"

const HomeLayout = () => {

  return (
    <>
    <div className={styles.homeLayout} id='home' >
        <Outlet/>
        <Footer/>
    </div>
  
   </>
  )
}

export default HomeLayout