import React, { useEffect, useState } from 'react'
import { GiHamburgerMenu } from "react-icons/gi"
import { Link } from "react-scroll";
import logo from "../../assets/images/icon.png"
import styles from '../../App.module.scss';

const Navbar = () => {

    const [scrollHeight, setScrollHeight] = useState(0)
    const [barHide, setBarHide] = useState(false)

      
    // const refToTop = useRef<HTMLInputElement>(null);
    
    const onScroll = (event: any) => {
        setScrollHeight(window.scrollY)
      }
      // const scrollToTop = () => {
      //   refToTop.current && refToTop.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // }
    
      useEffect(() => {
        window.addEventListener("scroll", onScroll, { capture: true });
        return () => window.removeEventListener("scroll", onScroll, { capture: true });
      }, []);

    
    const handleHideClick = () => {
        setBarHide(!barHide)
      }



  return (
    <div className={`${styles.navbar_cont} ${scrollHeight > 35 ? styles.navbar_cont1 : ""}`}>
        <div className={styles.navbar_subcont}>
          <div className={styles.logo_cont}>
            <img className={styles.logo} src={logo} alt="logo" />
            <div className={styles.hamburger} onClick={handleHideClick}><GiHamburgerMenu /></div>
          </div>
          <ul className={styles.rt_cont}>
            <li className={styles.navfields}>
              <Link className={scrollHeight > 35 ? styles.link1 : styles.link2} href="#home" activeClass="navfields"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2000}>Home</Link>
            </li>
            <li className={styles.navfields}>
              <Link activeClass="navfields"
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2000} className={scrollHeight > 35 ? styles.link1 : styles.link2} href="#about">About</Link>
            </li>
            <li className={styles.navfields}>
              <Link activeClass="navfields"
                to="services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={2500} className={scrollHeight > 35 ? styles.link1 : styles.link2} href="#services">Services</Link>
            </li>
            <li className={styles.navfields}>
              <Link activeClass="navfields"
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={3000} className={scrollHeight > 35 ? styles.link1 : styles.link2} href="#contact">Contact</Link>
            </li>
          </ul>
          {barHide ?
            <ul className={styles.rt_cont1}>
              <li className={styles.navfields} onClick={handleHideClick}>
                <a className={scrollHeight > 35 ? styles.link1 : styles.link2} href="#home">Home</a>
              </li>
              <li className={styles.navfields} onClick={handleHideClick}>
                <a className={scrollHeight > 35 ? styles.link1 : styles.link2} href="#about">About</a>
              </li>
              <li className={styles.navfields} onClick={handleHideClick}>
                <a className={scrollHeight > 35 ? styles.link1 : styles.link2} href="#services">Services</a>
              </li>
              <li className={styles.navfields} onClick={handleHideClick}>
                <a className={scrollHeight > 35 ? styles.link1 : styles.link2} href="#contact">Contact</a>
              </li>
            </ul> : ""
          }
        </div>
      </div>
  )
}

export default Navbar